<template>
    <div class="main">
        <div class="result">{{ result }}</div>
    </div>
</template>

<script>
import { doRequest } from '@/utils/request'
// import { Toast } from 'vant'

export default {
    data() {
        return {
            orderId: '',
            result: '',
            timer: null,
        }
    },
    mounted() {
        this.orderId = this.$route.query.out_trade_no
        if (this.orderId.length == 0) {
            this.result = '无效的订单号'
        }
        this.timer = setInterval(() => {
            doRequest('/recharge/result', {
                order_id: this.orderId,
            }, this.$route.query).then(res => {
                if (res.result == '订单不存在' || res.result == '支付成功') {
                    clearInterval(this.timer)
                }
                this.result = res.result
            })
        }, 1000)
    },
}
</script>

<style scoped>
.main {
    width: 375px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.result {
    margin-top: 100px;
    font-size: 40px;
}
</style>